










































































































import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Profile extends Vue {
	private com = "person"
}
